import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-29fdc321"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_2 = { style: {"color":"red"} }
const _hoisted_3 = { style: {"color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tree_select = _resolveComponent("el-tree-select")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: _ctx.form,
    rules: _ctx.rules,
    "label-width": "80px",
    class: "userCard"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CardForm, {
        formItems: _ctx.compParams.formItems,
        form: _ctx.form,
        rules: _ctx.rules,
        disabled: _ctx.disabled,
        refMap: _ctx.refMap,
        colNum: 1
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('user.form.name'),
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                onInput: _cache[0] || (_cache[0] = e => _ctx.form.name = _ctx.valid(e)),
                modelValue: _ctx.form.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
                maxlength: "50",
                placeholder: _ctx.$t('user.form.name_placeholder'),
                disabled: _ctx.disabled || _ctx.otherParams.disableField,
                clearable: ""
              }, null, 8, ["modelValue", "placeholder", "disabled"])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.utils.Tools.getPropFromVuex('needPerson'))
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: "所属人员",
                prop: "personId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    ref: el=>_ctx.refMap.set('personId',el),
                    modelValue: _ctx.form.personId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.personId) = $event)),
                    placeholder: "请选中账号所属人员",
                    disabled: _ctx.disabled || _ctx.otherParams.disableField,
                    clearable: "",
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'personId'}), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.otherParams.showType)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: "账号类型",
                prop: "type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    ref: el=>_ctx.refMap.set('type',el),
                    modelValue: _ctx.form.type,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.type) = $event)),
                    placeholder: "请输入账号类型",
                    disabled: _ctx.disabled || _ctx.otherParams.disableField,
                    clearable: "",
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'type'}), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('user.form.roleId'),
            prop: "roleId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                ref: el=>_ctx.refMap.set('roleId',el),
                modelValue: _ctx.form.roleId,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.roleId) = $event)),
                placeholder: _ctx.$t('user.form.roleId_placeholder'),
                multiple: "",
                disabled: (_ctx.disabled || _ctx.otherParams.disableField) && _ctx.otherParams.roleDisable,
                clearable: "",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'roleId'}), (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value,
                      disabled: item.disabled
                    }, null, 8, ["label", "value", "disabled"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder", "disabled"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: "区域数据",
            prop: "zoneId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tree_select, {
                modelValue: _ctx.form.zoneId,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.zoneId) = $event)),
                data: _ctx.otherParams.zoneTreeData,
                "render-after-expand": false,
                "check-strictly": "",
                style: {"width":"100%"},
                placeholder: "请选择区域",
                "show-checkbox": "",
                multiple: "",
                clearable: ""
              }, null, 8, ["modelValue", "data"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('user.intiPwd')) + ": xy123456", 1),
                _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.form.stopInfo), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["formItems", "form", "rules", "disabled", "refMap"])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}