import {ref, computed, getCurrentInstance, reactive, toRefs, onBeforeMount,onMounted, defineComponent,nextTick} from 'vue';
import UserCardUtil,{IUserCardDataObj} from "@/views/sysviews/user/ts/userCard/userCardUtil";

export default defineComponent({
    name: "userCard",
    title: "账号管理",
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUserCardDataObj=reactive<IUserCardDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            formRef:null,
            disabled:false,
            compParams: {
                modelPath: utils.Api.buildUrl("/user")
            },
            personData: [],
            roleData: [],
            form: {} as any,
            rules: utils.UtilPub.commonFormValidRule([
                /*{code:proxy.$t('user.form.code_rule')},*/
                {name:proxy.$t('user.form.name_rule')},
                {type:proxy.$t('请选择账号类型')},
                {roleId:proxy.$t('请选择角色')},
                {zoneId:proxy.$t('请选择区域数据')}
                ]),
            otherParams:{
                disableField:false,
                roleDisable:true,//有时候角色需要单独拿出来控制
                showType:true,
                zoneTreeData:[]
            }
        });
        onBeforeMount(()=>{dataObj.utilInst=new UserCardUtil(proxy,dataObj)})
        onMounted(()=>{
           //构建下拉数据放到了beforeOpen里面是因为，在那里可以拿到是否新增，对于构建角色下拉要用到，
            //因为可能是上级查看下级的用户卡片，如果角色下拉只加载本机构的角色数据，这样就可能是一串数字，所以如果是load，
            // 应该是加载本机构及下级机构的角色数据才对
        })
        const beforeOpen=async(res:any,addOrLoad:string,cardEngine:any)=>{
            if('/load'==addOrLoad && res.data.roleId)dataObj.form.roleId=JSON.parse(res.data.roleId);
            if('/load'==addOrLoad && res.data.zoneId)dataObj.form.zoneId=JSON.parse(res.data.zoneId);
            nextTick(async() =>{
                await dataObj.utilInst.buildPersonData();
                await dataObj.utilInst.buildRoleData({addOrLoad:addOrLoad.substr(1),roleId:res.data.roleId});
                await dataObj.utilInst.buildZoneTreeData({});
            })
            dataObj.otherParams.disableField=res.disableField;
            if(res.disabled!=undefined)dataObj.disabled=res.disabled;
            if(res.roleDisable!=undefined)dataObj.otherParams.roleDisable=res.roleDisable;
        }
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'personId'==params.comboId){
                    return dataObj.personData;
                }
                if(params && 'roleId'==params.comboId){
                    return dataObj.roleData;
                }
                if(params && 'type'==params.comboId){
                    return [
                        {label:'财务经理',value:0},{label:'财务专员',value:1},
                        {label:'业务总监',value:2},{label:'业务经理',value:3},
                        {label:'业务员',value:4},{label:'单证经理',value:5},
                        {label:'单证专员',value:6},{label:'订单经理',value:7},
                        {label:'订单专员',value:8},{label:'其它',value:9},
                    ];
                }
            }
        })

        const startHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'start',operateType:'changeUserStatus'});
        }
        const stopHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'stop',operateType:'changeUserStatus'});
        }
        const resetPwdHandler=()=>{
            dataObj.utilInst.resetPwd()
        }

        return{
            ...toRefs(dataObj),beforeOpen,comboSelect,startHandler,stopHandler,resetPwdHandler
        }
    }
});